.drag-drop-zone {
  padding: 2rem;
  margin-left: 2rem;
  margin-top: 1rem;
  text-align: center;
  border: dashed;
  border-radius: 0.5rem;
  border-color: var(--components-color);
}

.drag-drop-zone p {
  color: var(--components-color);
  font-size: 20px;
  margin-top: 1rem;
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}

.drag-drop-zone-on-drag {
  background-color: lightseagreen;
}

.drag-drop-zone-on-drag p {
  color: white;
}
