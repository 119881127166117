.attachments-label {
  padding-top: 17px;
}

.mails-info-container {
  margin-left: 2rem;
}

.no-attachments-color {
  color: red;
  font-weight: 600;
  font-style: italic;
}

.label {
  color: var(--components-color);
  font-weight: 600;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
