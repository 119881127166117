.footer {
  background-color: var(--components-color);
  border: 1px solid var(--components-color);
  box-sizing: border-box;
  padding: 0.5rem;
  left: 0;
  bottom: 0;
  color: white;
  vertical-align: middle;
  position: fixed;
  height: 5%;
  width: 100%;
}