.rpa-dropzones-container {
  padding-bottom: 2rem;
}

.tiles-container {
  padding: 20px 0px;
}

.tile {
  padding: 1.3rem;
  margin-left: 1rem;
  margin-top: 1rem;
  text-align: center;
  border: dashed;
  border-radius: 0.7rem;
  border-color: var(--components-color);
  height: 45%;
}

.tile p {
  font-size: 1.5vw;
  font-weight: 600;
  vertical-align: middle;
}

.used-tile {
  border: solid thick var(--components-color);
}

.validation-errors-header {
  color: red;
}

.in-middle {
  top: 50%;
  transform: translateY(-15%);
}

.MuiChip-root span {
  font-size: 1.2vw;
}

@media screen and (min-width: 1000px) {
  p.in-middle {
    font-size: 16px;
  }
}

@media screen and (min-width: 850px) {
  span.MuiChip-label {
    font-size: 12px;
  }
}