.application-title {
  flex: 1.3;
  text-align: right;
}

.error-icon {
  display: inline;
  margin: 0.5rem;
}

.error-message {
  display: inline;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #1fb6ad;
}

.sign-out-button {
  display: inline;
}

.user-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: #1fb6ad;
}

.user-info-container {
  flex: 1 1;
  text-align: right;
}

.user-name {
  display: inline;
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-right: 2rem;
  margin-left: 2rem;
}

.folder-link-container {
  text-align: left;
}

.folder-link-hidden {
  display: none;
}

.folder-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: #1fb6ad;
}

.folder-icon-disabled {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: gray;
}

.user-info-fixed {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1rem;
  z-index: 1;
}
