.add-new-assured-row {
  color: #1FB6AD;
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.MuiInputBase-root {
  width: 100%;
}