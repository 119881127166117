.critical-label {
  margin-left: 0px;
}

.form-fields-container {
  padding-right: 20px;
}

.form-container {
  padding-bottom: 2rem;
}

.form-footer {
  border-top: 1px solid var(--components-color);
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
}

.form-section {
  padding: 15px 0px;
}