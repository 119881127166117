.text-editor {
  border: 1px solid rgba(0, 0, 0, 0.42);
}

.form-footer {
  border-top: 1px solid var(--components-color);
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
}
